
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { getTagGroupList, setTagGroup } from "@/api/request/tag";

//组件
@Component({
  name: "TagLGroup",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //标签列表
  private dialogShow: boolean = false; //显示对话框
  private listLoading: boolean = false; //显示等待界面
  private dialogLoading: boolean = false; //显示对话框等待

  //表单数据
  private form: any = {
    name: "", //名称
    remark: "", //备注
    id: undefined, //ID
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagGroupList();

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //添加标签
  private handleAdd(): void {
    //初始化数据
    this.form = {
      label: "", //标签
      remark: "", //备注
      id: undefined, //ID
    };

    //显示界面
    this.dialogShow = true;
  }

  //创建标签组
  private async createData() {
    //异常处理
    if (!this.checkName()) return;
    if (!this.checkRemark()) return;

    //显示等待
    this.dialogLoading = true;

    //创建标签
    await setTagGroup(this.form);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //更新标签组
  private async updateData() {
    //异常处理
    if (!this.checkName()) return;
    if (!this.checkRemark()) return;

    //显示等待
    this.dialogLoading = true;

    //创建标签
    await setTagGroup(this.form);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //检测名称
  private checkName(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.form.name)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入标签名称！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id === undefined;
  }

  //检测备注
  private checkRemark(): boolean {
    //判断异常
    if (!GFunc.checkstring(this.form.remark)) {
      //显示提示
      Message({
        type: "error",
        duration: 5 * 1000,
        message: "请输入标签备注！",
      });

      //返回失败
      return false;
    }

    //返回成功
    return true;
  }

  //编辑标签
  private handleEdit(data: any): void {
    //数据赋值
    this.form = data;

    //显示界面
    this.dialogShow = true;
  }
}
